import React, { useState } from "react";
import Page from "../components/Page";
import Layout from "../components/Layout";
import Space from "../components/elements/Space/Space";
import Numbers, { Number } from "../components/components/Numbers/Numbers";
import ContactLineCTA from "../components/components/ContactLineCTA/ContactLineCTA";
import ServiceInformation from "../components/components/ServiceInformation/ServiceInformation";
import Herobanner from "../components/elements/Herobanner/Herobanner";
import Button from "../components/elements/Buttons/Button";
import Text from "../components/atoms/Text/Text";
import CentralLayout from "../components/atoms/CentralLayout/CentralLayout";
import Section from "../components/atoms/Section/Section";
import ContactForm from "../components/components/ContactForm/ContactForm";
import DoubleColumns, { RightTop, RightBottom, Left, Right } from "../components/elements/DoubleColumns/DoubleColumns";
import GreenTitle from "../components/atoms/GreenTitle/GreenTitle";

import { ContainerRow } from "../components/atoms/Containers/Conainers";
import Testimonial, { TestimonialSlider } from "../components/elements/Testimonial/Testimonial";
import DoubleArrow from "../components/atoms/DoubleArrow/DoubleArrow";
import ClientsTestimonialsImages from "../components/components/ClientsTestimonialsImages/ClientsTestimonialsImages";
import { scrollToContact, scrollToId } from "../utility/function";
import FormSlider, { FormSlide } from "../components/components/FormSlider/FormSlider";
import Shape from "../components/elements/Shape/Shape";
import Clients from "../components/components/Clients/Clients";


import Lottie from "react-lottie";
import * as animation from "../../static/lottie/coder.json";

const Home = () => {
  const [formOpen, setFormOpen] = useState(false);

  const toContatti = () => {
    setFormOpen(true);
    scrollToContact();
  };

  return (
    <Page title='Leading your growth' description='Applichiamo la tecnologia al performance marketing per far scalare il business dei nostri Clienti.' image='/favicon.png'>
      <Layout navigationCallback={toContatti}>
        <Herobanner backgroundUrl={"/herobanner/homepage.svg"}>
          <h1 className='mb-3'>Leading your growth</h1>
          <Text>Applichiamo la tecnologia al performance marketing per far scalare il business dei nostri Clienti.</Text>
          <Button
            customClass='mt-6'
            onClick={() => {
              scrollToId("numbers");
            }}
          >
            Scopri di più
          </Button>
        </Herobanner>

        <Space mobile={10} table={15} desktop={20} />

        <div id='numbers'>
          <Numbers
            text={
              <>
                Ti aiutiamo a far crescere la tua Azienda,
                <br />
                creando <span style={{ textDecoration: "underline" }}>contatti</span> e generando <span style={{ textDecoration: "underline" }}>vendite</span>.
              </>
            }
          >
            <Number number={"100+"} text={"Clienti soddisfatti"} />
            <Number number={"500.000+"} text={"Leads generati"} />
            <Number number={"30K"} text={"Righe di codice"} />
          </Numbers>
        </div>

        <Space mobile={10} table={4} desktop={10} />

        {/*  <ContactLineCTA callback={toContatti} /> */}

        {/*   <Space mobile={10} table={4} desktop={20} /> */}

        <ServiceInformation />

        {/*  <FormSlider>
          <FormSlide label='Ricerca' image={"/slide/step_1.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>
                L’utente effettua una ricerca sul web
                <br />
                specifica per il suo bisogno.
              </strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Landing page' image={"/slide/step_2.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>“Atterra” su un sito web a noi affiliato.</strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Form' image={"/slide/step_3.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>Compila il nostro form multi-step.</strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Verifica automatica' image={"/slide/step_4.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>
                Validazione automatica
                <br />
                di una parte dei dati.
              </strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Verifica umana' image={"/slide/step_5.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>
                Verifica telefonica
                <br />
                da parte di un nostro operatore dei dati.
              </strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Trasmissione lead' image={"/slide/step_6.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>
                Il lead viene trasmesso
                <br />
                via email o sul vostro CRM.
              </strong>
            </div>
            <Text></Text>
          </FormSlide>
        </FormSlider>

        <FormSlider title='Campagne dirette'>
          <FormSlide label='Ricerca' image={"/slide/step_1.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>
                L’utente effettua una ricerca sul web
                <br />
                specifica per il suo bisogno.
                </strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Landing page cliente' image={"/slide/step_2.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>Viene veicolato, attraverso il nostro network, alla tua landing page/sito web.</strong>
            </div>
            <Text></Text>
          </FormSlide>
          <FormSlide label='Funnel' image={"/slide/step_3_2.png"}>
            <div className='mt-3 text-center mb-4 px-2'>
              <strong>
                Entra nel tuo funnel specifico <br></br> (leads generation/acquisto online etc.).
              </strong>
            </div>
            <Text></Text>
          </FormSlide>
        </FormSlider> */}

        <Space mobile={20} table={20} desktop={20} />

        <Section>
          <ContainerRow>
            <div className="col-md-7 px-5">
              <Lottie
                className="m-3"
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animation.default,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />

              <Shape direction='inverse' />
            </div>
            <div className="col-md-5 justify-content-center align-self-center">

              <GreenTitle caps>Tecnologia</GreenTitle>
              <h3 className="my-1">Il nostro cuore è Tech</h3>
              <Text>
                Adottiamo soluzioni tecnologiche avanzate come ad esempio <b>OTP</b> o <b>anonymized fingerprint</b> per
                garantire un'elevata qualità del nostro traffico.
            </Text>
            </div>
          </ContainerRow>
        </Section>
        {/*   <DoubleColumns>
          <Left>
            
          </Left>
          <Right>
            <GreenTitle caps>Tecnologia</GreenTitle>
            <h3 className="my-1">Il nostro cuore è Tech</h3>
            <Text>
              Adottiamo soluzioni tecnologiche per garantire un’elevata qualità dei nostri leads,
              come ad esempio <b>OTP</b> in caso di leads generati su asset proprietari o <b>anonymized fingerprint</b> per minimizzare il numero di leads “fake”.
            </Text>
          </Right>
        </DoubleColumns>
 */}

        <Space mobile={10} table={10} desktop={8} />

        <Clients />

        <Space mobile={20} table={20} desktop={16} />

        <Section background className='relative'>
          <Shape />
          <div id='contact-form'>
            {!formOpen && (
              <CentralLayout>
                <Space mobile={8} table={9} desktop={10} />
                <h3 className='mb-1'>Vuoi saperne di più?</h3>
                <h4 className='my-0'>Non esitare</h4>
                <Button customClass='mt-6' black onClick={() => setFormOpen(true)}>
                  Contattaci
                </Button>
                <Space mobile={8} table={9} desktop={10} />
              </CentralLayout>
            )}
            {formOpen && <ContactForm text_line1='Richiedi ora informazioni' />}
          </div>
        </Section>
      </Layout>
    </Page >
  );
};

export default Home;
