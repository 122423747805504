import React from "react";
import style from "./ServiceInformation.module.scss";
import cx from "classnames";

import GridThree from "./../../elements/Grid/GridThree";
import GridCard from "./../../elements/Grid/GridCard";
import TextAndTitlesBlock from "../../elements/TextBlocks/TextAndTitlesBlock";
import Text from "../../atoms/Text/Text";
import Shape from "../../elements/Shape/Shape";

const ServiceInformation = () => (
  <section className='relative'>
    <Shape />
    <div className={"container"}>
      <div className={cx("row", style.background)}>
        <div className='col-12  col-lg-6 order-2 order-lg-1'>
          <GridThree>

            <GridCard icon={"graph"} title={"Finance"} to={"./cosa-facciamo"}>
              Alcuni tra i più importanti intermediari finanziari utilizzano il nostro network per le loro campagne a performance.
            </GridCard>
            <GridCard icon={"insurance"} title={"Insurance"} to={"./assicurativo"}>
              Già più di 800 intermediari acquistano leads geo-localizzati sulla nostra piattaforma.<br></br> Unisciti a loro.
            </GridCard>
            <GridCard icon={"phone"} title={"Telco/Utilities"} to={"./cosa-facciamo"}>
              Trova nuovi clienti interessati a cambiare piano tariffario o ad effettuare uno switch per contratti luce-gas.
            </GridCard>
            <GridCard icon={"ecommerce"} title={"E-commerce"} to={"./cosa-facciamo"}>
              Utilizza il performance marketing per generare nuove vendite e ampliare la tua base clienti attraverso i nostri publishers.
            </GridCard>
          </GridThree>
        </div>
        <div className='col-12 offset-sm-1 col-sm-10 offset-lg-1 col-lg-5 mb-10 mb-lg-0 order-1 order-lg-2 d-flex align-items-center'>
          <TextAndTitlesBlock greenTitle={"Cosa facciamo"} title={"I nostri servizi."} subTitle={""}>
            <Text>Attraverso un network di siti premium veicoliamo le campagne dei nostri Clienti per generare leads qualificati e vendite.</Text>
          </TextAndTitlesBlock>
        </div>
      </div>
    </div>
  </section>
);

export default ServiceInformation;
