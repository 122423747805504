import React from "react";
import style from "./TextAndTitlesBlock.module.scss";
import GreenTitle from "../../atoms/GreenTitle/GreenTitle";

const TextAndTitlesBlock = ({title, subTitle, greenTitle, children}) => (
  <div>
    {greenTitle && <GreenTitle caps>{greenTitle}</GreenTitle>}
    {title && <h3 className={style.title}>{title}</h3>}
    {subTitle && <div className={style.subTitle}>{subTitle}</div>}
    {children}
  </div>
);
export default TextAndTitlesBlock;
